export default function () {
    return {
        id: '',
        init() {
            this.id = this.$el.dataset?.productId || '';
        },
        isAddedToWishList() {
            const data = this.$store.mageCacheStorage.getStorageData() || {};

            if (!data.wishlist) return false;

            return data.wishlist.items.some(
                (item) =>
                    item.product_sku === this.sku ||
                    parseInt(item.product_id) === parseInt(this.id)
            );
        }
    };
}
