export default {
    breakpoints: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        '2xl': 1438,
        '3xl': 1600
    },
    name: '',
    minWidth: 0,
    mq: null,
    init() {
        this.test();

        window.addEventListener(
            'resize',
            () => {
                this.test();
            },
            true
        );
    },
    test() {
        const result = {};

        Object.entries(this.breakpoints).forEach(([key, value]) => {
            const mq = window.matchMedia(
                `only screen and (min-width: ${value}px)`
            );

            if (mq.matches) {
                result.mq = mq;
                result.name = key;
                result.minWidth = value;
            }
        });

        this.mq = result.mq;
        this.name = result.name;
        this.minWidth = result.minWidth;
    },
    isMobile() {
        return this.minWidth < this.breakpoints.lg;
    }
};
