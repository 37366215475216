import { setupPage, initAlpine } from '~lib/storefront';
import './pages/default';
import './globals';
import '@splidejs/splide/dist/css/splide-core.min.css';
import './styles/style.css';

const swPage = () => import('./pages/sw-page');
const pagesMap = {
    cms_index_index: () => import('./pages/homepage'),
    catalog_product_view: () => import('./pages/product'),
    blog_category_view: swPage,
    blog_author_view: swPage,
    blog_index_index: swPage,
    cms_page_view: swPage,
    blog_post_view: swPage,
    blog_tag_view: swPage,
    blog_author_index: swPage,
    blog_search_index: swPage
};

setupPage(pagesMap, window.context.page).then(() => initAlpine());
