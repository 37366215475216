const consentCookiesMap = {
    necessary: 'cookieConsentPreferencesGranted',
    marketing: 'cookieConsentMarketingGranted',
    analytics: 'cookieConsentAnalyticsGranted'
};

export default function ({ cookieKey = 'cookies-consent' }) {
    return {
        config: window.consentCookieConfig,
        cookieKey,
        isActive: false,
        isActivePreferences: false,
        groups: [],
        selectedGroup: null,
        activeBodyClass: 'cookie-preferences-active',

        init() {
            this.initReferer();
            this.groups = this.getGroups();
            this.isActive = !this.getCookies();

            this.$watch('isActivePreferences', () => {
                const body = document.querySelector('body');

                this.isActivePreferences
                    ? body.classList.add(this.activeBodyClass)
                    : body.classList.remove(this.activeBodyClass);
            });
        },

        close() {
            this.isActive = false;
            this.isActivePreferences = false;

            localStorage.setItem(
                'savedReferrer',
                JSON.stringify({
                    referrer: document.referrer
                })
            );
            window.location.reload();
        },

        save() {
            const payload = this.groups.map((group) =>
                this.prepareGroupItemForSave(group)
            );

            this.saveCookie(this.cookieKey, JSON.stringify(payload));
            this.saveGtmConsentCookies();
            this.close();
        },

        saveAll() {
            this.setActiveAllCookies();
            this.save();
        },

        saveCookie(key, value) {
            const expiredDate = new Date(
                Date.now() + 86400000 * 365
            ).toUTCString();

            document.cookie =
                key + '=' + value + '; expires=' + expiredDate + ';path=/;';
        },

        initReferer() {
            const referrer = localStorage.getItem('savedReferrer')
                ? JSON.parse(localStorage.getItem('savedReferrer')).referrer
                : null;

            if (referrer) {
                Object.defineProperty(document, 'referrer', {
                    get: function () {
                        return referrer;
                    }
                });
                localStorage.removeItem('savedReferrer');
            }
        },

        getCookieByName(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },

        getCookies() {
            return this.getCookieByName(this.cookieKey) || false;
        },

        prepareGroupItem(item) {
            return {
                groupId: item.group_id,
                parentId: item.parent_id,
                code: item.code,
                name: item.name,
                description: item.description,
                storeId: item.store_id,
                level: parseInt(item.level),
                position: parseInt(item.position),
                isActive: !!parseInt(item.is_active),
                isEssential: !!parseInt(item.is_essential),
                items:
                    item.items?.map((item) => this.prepareCookieItem(item)) ||
                    [],
                children:
                    item.children?.map((item) => this.prepareGroupItem(item)) ||
                    []
            };
        },

        prepareCookieItem(item) {
            return {
                itemId: item.item_id,
                groupId: item.group_id,
                code: item.code,
                name: item.name,
                description: item.description,
                type: item.type,
                lifetime: item.lifetime,
                isActive: !!parseInt(item.is_active),
                position: parseInt(item.position),
                storeId: item.store_id
            };
        },

        prepareGroupItemForSave({ isActive, code, items = [], children = [] }) {
            return {
                code,
                isActive,
                items: items.map((item) => this.prepareCookieItemForSave(item)),
                children: children.map((group) =>
                    this.prepareGroupItemForSave(group)
                )
            };
        },

        prepareCookieItemForSave({ isActive, code }) {
            return {
                code,
                isActive
            };
        },

        getAllGroupCookieItems(group) {
            const result = [...group.items];

            group.children.map((childItem) => {
                result.push(...childItem.items);
            });

            return result;
        },

        getGroups() {
            const items = Object.values(this.config);

            return items
                .map((item) => this.prepareGroupItem(item))
                .sort((prev, next) => prev.position - next.position)
                .filter((item) => item.isActive);
        },

        showGroupDetails(item) {
            this.selectedGroup = item;
        },

        setGroupItemsAndChildState() {
            this.$nextTick(() => {
                this.groups.map((group) => {
                    const { isActive } = group;

                    group.children.map((groupChild) => {
                        groupChild.isActive = isActive;
                        groupChild.items.map(
                            (item) => (item.isActive = isActive)
                        );
                    });

                    group.items.map((item) => (item.isActive = isActive));
                });
            });
        },

        setActiveAllCookies() {
            this.groups.map((group) => {
                group.isActive = true;
                group.children.map(
                    (childGroup) => (childGroup.isActive = true)
                );
                group.items.map((item) => (item.isActive = true));
            });
        },

        saveGtmConsentCookies() {
            this.groups.map((group) => {
                const key = consentCookiesMap[group.code];
                const value = group.isActive ? '1' : '0';

                this.saveCookie(key, value);
            });
            this.saveCookie('cookieConsentGranted', '1');
        }
    };
}
