import { Splide } from '@splidejs/splide';

const defaultOptions = {
    type: 'loop',
    perPage: 1,
    classes: {
        pagination: 'splide__pagination',
        page: 'splide__pagination__page'
    }
};

export default function ({ onlyMobile = false, options = {} }) {
    return {
        onlyMobile,
        options: {
            ...defaultOptions,
            ...options
        },
        slider: null,

        init() {
            this.setupWatchers();

            if (this.onlyMobile && !this.isMobile()) {
                return;
            }

            this.mount();
        },

        setupWatchers() {
            this.$watch('isMobile()', (val) => {
                if (!this.onlyMobile) return;

                if (val && !this.slider) this.mount();

                if (!val) this.destroy();
            });

            this.$watch('$store.mq.minWidth', () => {
                this.slider && this.slider.go(0);
                this.setActiveNavItems(0);
            });
        },

        isMobile() {
            return this.$store.mq.isMobile();
        },

        mount() {
            const startIndex = 0;

            if (this.slider) {
                this.slider.mount();
                this.setActiveNavItems(startIndex);
                return;
            }

            this.$nextTick(() => {
                this.slider = new Splide(
                    this.$el.querySelector('.splide'),
                    this.options
                ).mount();
                this.setupSliderEvents();
                this.setActiveNavItems(startIndex);
            });
        },

        destroy() {
            if (!this.slider) return;

            this.slider.destroy();
            this.slider = null;
        },

        setupSliderEvents() {
            this.slider.on('moved', (index) => {
                this.setActiveNavItems(index);
            });

            this.slider.on('resized', () => {
                this.slider && this.slider.go(0);
                this.setActiveNavItems(0);
            });
        },

        setActiveNavItems(index) {
            const activeClass = 'show';
            const navItems = [
                ...this.$el.querySelectorAll('li[role="presentation"]')
            ];

            if (!navItems.length) return;

            const activeItems = this.getClosestElements(navItems, index);

            navItems.forEach((item) => item.classList.remove(activeClass));
            activeItems.forEach((item) => item.classList.add(activeClass));
        },

        getClosestElements(target = [], index = 0, limit = 5) {
            if (target.length <= limit) {
                return target;
            }

            if (index === 0) {
                return target.slice(index, limit);
            }

            if (index === 1) {
                return target.slice(index - 1, limit);
            }

            const result = target.slice(index - 2, index + 3);

            if (result.length < limit) {
                return result.concat(target.slice(0, limit - result.length));
            }

            return result;
        }
    };
}
