import { asyncRequire } from '~lib/storefront';
import { initSlider } from './slider-utils';

export const initBrandSlider = async (sliderSelector) => {
    const sliderConfig = {
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    };

    await asyncRequire(['jquery', 'slick']);
    initSlider(sliderSelector, sliderConfig);
};
