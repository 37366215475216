import { Splide } from '@splidejs/splide';

export default function (isSimple) {
    const mainGalleryConfig = {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false
    };

    const thumbnailConfig = {
        fixedWidth: 64,
        fixedHeight: 64,
        gap: 16,
        rewind: true,
        pagination: false,
        isNavigation: true,
        perPage: 3,
        breakpoints: {
            768: {
                fixedWidth: 36,
                fixedHeight: false,
                focus: 'center',
                gap: 0
            }
        }
    };

    return {
        isSimpleType: isSimple,
        init() {
            if (!this.isSimpleType) {
                this.initNormalMode();
            }
            this.initFullScreenMode();
        },

        initNormalMode() {
            this.gallerySlider = new Splide(
                '#main-carousel',
                mainGalleryConfig
            );
            this.navSlider = new Splide('#thumbnail-carousel', thumbnailConfig);
            this.gallerySlider.sync(this.navSlider);
            this.gallerySlider.mount();
            this.navSlider.mount();
        },

        initFullScreenMode() {
            const gallery = document.getElementById('main-carousel');

            this.$root.insertAdjacentHTML(
                'beforeend',
                '<i class="close close-fullscreen icon-close"></i>'
            );
            this.$root
                .querySelector('.close')
                .addEventListener(
                    'click',
                    this.switchFullscreen.bind(this, true)
                );
            if (this.isSimpleType) {
                gallery.addEventListener(
                    'click',
                    this.switchFullscreen.bind(this, false)
                );
            } else {
                gallery
                    .querySelector('.splide__list')
                    .addEventListener(
                        'click',
                        this.switchFullscreen.bind(this, false)
                    );
            }
        },

        switchFullscreen(shouldHide = false) {
            if (shouldHide) {
                this.$root.classList.remove('fullscreen');
                document.body.classList.remove('stop-scrolling');
            } else {
                this.$root.classList.add('fullscreen');
                document.body.classList.add('stop-scrolling');
            }

            if (this.navSlider) this.navSlider.refresh();
            this.gallerySlider.refresh();
        }
    };
}
