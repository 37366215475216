export const initNavigationDots = (slick) => {
    if (!slick.$dots) return;

    const dotsList = Array.from(slick.$dots.find('li'));
    const currentIndex = dotsList.findIndex((item) =>
        item.classList.contains('slick-active')
    );
    const getNearestItem = (list, index, shift = 0) => {
        return list[(index + list.length + shift) % list.length];
    };

    if (currentIndex < 3) {
        dotsList.slice(0, 3).map((page) => (page.style.display = 'block'));
    }

    const current = getNearestItem(dotsList, currentIndex);
    const prePrevious = getNearestItem(dotsList, currentIndex, -2);
    const previous = getNearestItem(dotsList, currentIndex, -1);
    const next = getNearestItem(dotsList, currentIndex, +1);

    dotsList.map((page) => (page.style.display = 'none'));

    [prePrevious, previous, current, next].map(
        (item) => (item.style.display = 'block')
    );
};

export const initSlider = (selector, config) => {
    const $el = window.jQuery(selector);

    $el.on('init', (event, slick) => {
        initNavigationDots(slick);
    });

    const $slick = $el.slick(config);

    $slick.on('afterChange', (event, slick) => {
        initNavigationDots(slick);
    });

    return $slick;
};

export const destroySlider = ($element) => {
    $element.slick('unslick');
};
