import { registerComponent, registerStore } from '~lib/storefront';
import messages from '../alpine/components/ui/messages';
import cookiesConsent from '../alpine/components/ui/cookies-consent';
import collapsible from '../alpine/components/ui/collapsible';
import subscriptionInpostSelect from '../alpine/components/subscription/inpost-select';
import productGallery from '~/alpine/components/product/product-gallery';
import wishlistStatus from '~/alpine/components/product/wishlist-status';
import global from '../alpine/stores/global';
import mq from '../alpine/stores/mq';
import mageCacheStorage from '~/alpine/stores/mage-cache-storage';
import slider from '~/alpine/components/ui/slider';

registerComponent('messages', messages);
registerComponent('cookiesConsent', cookiesConsent);
registerComponent('collapsible', collapsible);
registerComponent('subscriptionInpostSelect', subscriptionInpostSelect);
registerComponent('productGallery', productGallery);
registerComponent('wishlistStatus', wishlistStatus);
registerComponent('slider', slider);

registerStore('global', global);
registerStore('mq', mq);
registerStore('mageCacheStorage', mageCacheStorage);
