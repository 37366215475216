import { asyncRequire } from '~lib/storefront';

export default function () {
    return {
        messages: [],
        timer: null,

        async init() {
            const [$, customerData] = await asyncRequire([
                'jquery',
                'Magento_Customer/js/customer-data',
                'mage/cookies'
            ]);

            const messages = customerData.get('messages').extend({
                disposableCustomerData: 'messages'
            });
            const cookieMessages = $.cookieStorage.get('mage-messages') || [];

            this.$watch('messages', () => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.messages.map((message) => (message.isHidden = true));
                }, 3000);
            });

            this.messages.push(
                ...cookieMessages.map((message) => this.extendMessage(message))
            );

            if ($.mage.cookies) {
                $.mage.cookies.set('mage-messages', '', {
                    samesite: 'strict',
                    domain: ''
                });
            }

            messages.subscribe((messages) => {
                if (messages?.messages) {
                    this.messages.push(
                        ...messages.messages.map((message) =>
                            this.extendMessage(message)
                        )
                    );
                }
            });
        },

        extendMessage(message) {
            return {
                ...message,
                isHidden: false
            };
        }
    };
}
