import { asyncRequire } from '~lib/storefront';
import cache from '../helpers/cache.js';

const storageKey = 'mage-cache-storage';

export default {
    storage: cache(storageKey),

    init() {
        this.onCatalogAddToCart(async () => {
            const [$] = await asyncRequire(['jquery']);

            $(window).scrollTop(0);
        });
    },

    startLoading() {
        document.body.dispatchEvent(new Event('processStart'));
    },

    stopLoading() {
        document.body.dispatchEvent(new Event('processStop'));
    },

    async onAjaxComplete(callback) {
        const [$] = await asyncRequire(['jquery']);

        $(document).on('ajaxComplete', () => {
            callback();
        });
    },

    async onCatalogAddToCart(callback) {
        const [$] = await asyncRequire(['jquery']);

        $(document).on('ajax:addToCart', () => {
            callback();
        });
    }
};
