export default function (initState = false) {
    return {
        isOpen: initState,

        open() {
            this.isOpen = true;
        },

        close() {
            this.isOpen = false;
        },

        toggle() {
            this.isOpen = !this.isOpen;
        }
    };
}
