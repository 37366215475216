import { asyncRequire } from '~lib/storefront';

export const nativeEvents = {
    customerDataReload: 'native:customer-data-reload'
};

export default {
    storageKey: 'mage-cache-storage',
    data: {},

    async init() {
        this.initStorageData();
        this.listenStoreUpdates();
        await this.initStorageEvents();
    },

    initStorageData() {
        const cachedData = this.getCachedData();

        this.setStorageData(cachedData);
    },

    async initStorageEvents() {
        const [$] = await asyncRequire(['jquery']);

        $(document).on('customer-data-reload', (event, data) => {
            window.dispatchEvent(
                new CustomEvent(nativeEvents.customerDataReload, {
                    detail: data
                })
            );
        });
    },

    getCachedData() {
        try {
            return JSON.parse(localStorage.getItem(this.storageKey));
        } catch (error) {
            console.log(error);
            return {};
        }
    },

    setStorageData(payload) {
        this.data = { ...payload };
    },

    getStorageData() {
        return this.data;
    },

    listenStoreUpdates() {
        const updateHandler = () => {
            setTimeout(() => {
                this.initStorageData();
            }, 0);
        };
        window.addEventListener(nativeEvents.customerDataReload, updateHandler);
    }
};
