import { asyncRequire } from '~lib/storefront';
import { destroySlider, initSlider } from './slider-utils';

export const initCategoriesSlider = async (sliderSelector) => {
    const sliderConfig = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true
    };
    let sliderEl = null;

    const [mediaCheck] = await asyncRequire(['matchMedia', 'jquery', 'slick']);

    mediaCheck({
        media: '(max-width: 768px)',
        entry: () => {
            if (sliderEl) return;

            sliderEl = initSlider(sliderSelector, sliderConfig);
        },
        exit: () => {
            if (!sliderEl) return;

            destroySlider(sliderEl);
            sliderEl = null;
        }
    });
};
