import { asyncRequire } from '~lib/storefront';

export default function ({ selectedPointName }) {
    return {
        selectedPoint: null,

        async init() {
            await this.loadInpostSdk();
            await this.initPointInformation(selectedPointName);
            this.initListeners();
        },

        async initPointInformation(pointId) {
            const info = await this.fetchPointInformation(pointId);

            if (info) {
                this.selectedPoint = info;
            }
        },

        initListeners() {
            document.addEventListener('onpointselect', (event) => {
                this.selectPoint(event);
            });
            document.addEventListener('click', (event) => {
                if (
                    event.target.matches(
                        '[data-inpost-modal-btn-close], [data-inpost-modal]'
                    )
                ) {
                    this.closeInpostModal();
                }
            });
            document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape') {
                    this.closeInpostModal();
                }
            });
        },

        async loadInpostSdk() {
            const { mode, sdkUrlProduction, sdkUrlTesting } =
                window.inpostConfig;

            if (mode === 'test') {
                await asyncRequire([sdkUrlTesting]);
            } else if (mode === 'prod') {
                await asyncRequire([sdkUrlProduction]);
            }
        },

        createInpostModal(pointType) {
            const containerDiv = document.createElement('div');
            const { apiToken } = window.inpostConfig;

            containerDiv.innerHTML = `
              <div data-inpost-modal class="inpost-modal">
                <div class="inpost-modal__container">
                  <div data-inpost-modal-btn-close class="btn-close"></div>
                  <inpost-geowidget onpoint="onpointselect" token="${apiToken}" language="pl" config="${pointType}"></inpost-geowidget>
                </div>
              </div>
            `;

            document.body.appendChild(containerDiv);
        },

        closeInpostModal() {
            document
                .querySelectorAll('[data-inpost-modal]')
                .forEach(function (element) {
                    element.remove();
                });
        },

        changePoint() {
            this.createInpostModal('parcelCollect');
        },

        selectPoint(event) {
            this.selectedPoint = event.detail;
            this.closeInpostModal();
        },

        submitForm(event) {
            if (this.selectedPoint.name) {
                event.target.submit();
            }
        },

        async fetchPointInformation(pointId) {
            const { mode, apiEndpointProduction, apiEndpointTesting } =
                window.inpostConfig;
            const url = `${
                mode === 'prod' ? apiEndpointProduction : apiEndpointTesting
            }/points/${pointId}`;

            return fetch(url, { method: 'GET' })
                .then((response) => {
                    if (!response.ok && response.status === 404) {
                        throw response;
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.error(error.json());
                });
        }
    };
}
