import Alpine from 'alpinejs';

export function registerComponent(name, component) {
    document.addEventListener('alpine:init', () => {
        Alpine.data(name, component);
    });
}

export function registerStore(name, store) {
    document.addEventListener('alpine:init', () => {
        Alpine.store(name, store);
    });
}

export function registerDirective(name, directive) {
    document.addEventListener('alpine:init', () => {
        Alpine.directive(name, directive);
    });
}
