const cache = (storageKey) => ({
    storageKey,
    data() {
        let json = localStorage.getItem(this.storageKey);
        return json === null || json === 'null' ? {} : JSON.parse(json);
    },
    get(key, def) {
        let result = this.data();
        let path = key.split('/');
        for (let i in path) {
            // eslint-disable-next-line no-prototype-builtins
            if (result.hasOwnProperty(path[i])) {
                result = result[path[i]];
            } else {
                result = undefined;
                break;
            }
        }
        if (def !== undefined && result === undefined) {
            return def;
        }
        return result;
    },
    set(key, value) {
        if (value === undefined) {
            value = null;
        }
        let obj = {};
        obj[key] = value;
        obj = this.nestStringProperties(obj);
        let result = this.mergeDeep(this.data(), obj);
        localStorage.setItem(this.storageKey, JSON.stringify(result));
    },
    clear() {
        localStorage.removeItem(this.storageKey);
    },
    nestStringProperties(obj) {
        if (!obj) {
            return {};
        }
        const isPlainObject = (obj) =>
            !!obj && obj.constructor === {}.constructor;
        const getNestedObject = (obj) =>
            Object.entries(obj).reduce((result, [prop, val]) => {
                prop.split('/').reduce(
                    (nestedResult, prop, propIndex, propArray) => {
                        const lastProp = propIndex === propArray.length - 1;
                        if (lastProp) {
                            nestedResult[prop] = isPlainObject(val)
                                ? getNestedObject(val)
                                : val;
                        } else {
                            nestedResult[prop] = nestedResult[prop] || {};
                        }
                        return nestedResult[prop];
                    },
                    result
                );
                return result;
            }, {});
        return getNestedObject(obj);
    },
    mergeDeep(target, source) {
        let output = Object.assign({}, target);
        if (typeof target === 'object' && typeof source === 'object') {
            Object.keys(source).forEach((key) => {
                if (typeof source[key] === 'object') {
                    if (!(key in target))
                        Object.assign(output, { [key]: source[key] });
                    else output[key] = this.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        return output;
    }
});

export default cache;
