export async function asyncRequire(args = []) {
    if (!Array.isArray(args)) throw new Error('Provided wrong arguments');

    return new Promise((res, rej) => {
        window.require(args, (...payload) => {
            if (!payload.length) rej('Cant load plugins');
            res(payload);
        });
    });
}
